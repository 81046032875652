.bs-offcanvas {
    padding: 4px 2px 4px 8px;
    background-color: rgb(245, 238, 228);
    overflow-x: hidden;
    overflow-y: auto;    
    z-index: 10000;
    top: 0px;
    border-left: solid #ccc 1px;
}


.bs-canvas-overlay {
    display: none;
    position: fixed !important;

    left: 0px; 
    top: 0px;
}
.bs-canvas-overlay-show {
    display: block;
    position: fixed !important;
    left: 0px; 
    top: 0px;
    opacity: 0.4;
    background-color: rgb(165, 165, 165);    
    z-index: 9999;
}

.bs-offcanvas-anim {
   transition: all .4s ease-out;
   -webkit-transition: all .4s ease-out;
   -moz-transition: all .4s ease-out;
   -ms-transition: all .4s ease-out;
}
.offcanvas_handle {
    cursor: col-resize;
    height: 100vh;
    width:1px;

    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(84,84,84,0.7511598389355743) 35%, rgba(221,221,221,1) 100%);
    opacity: 0.1;
}
.offcanvas_handle:hover {
    opacity: 0.2;
}
.cthrough {
    opacity: 0.9;
}