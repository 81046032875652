$light-gray: #cccccc;
$dark-yellow: rgb(199, 124, 18);
$main-yellow: #fc8f24;
$main-blue: #0883ba;
$light-blue: #6ebde6;
$validation-green: #136841;
$validation-red: #a41c2a;
$thick-blue: #00527c;
$dark-blue: rgb(4, 57, 84);
$text-gray: rgb(75, 75, 75);


$breakdown-width:900px;

body, #root  {
  height: 100vh;
  scrollbar-width: thin;
}

#anicanvas {
  width: 100%;
  height: 100%;
  background: linear-gradient(-5deg, $dark-blue 0%, $thick-blue 20%, $main-blue 50%, $main-yellow 50%, rgb(220, 137, 20)  75%, $dark-yellow 100%);
  z-index: 0;
}
#loginbox  {
  color: $dark-yellow;
  color: black;
  text-align: center;
  position: relative;
  top: 45%;
  -ms-transform: translateY(-45%);
  -webkit-transform: translateY(-45%);
  transform: translateY(-45%);
  z-index: 100;
}
.logoheader {
    font-family: "Roboto";
    text-transform: uppercase;
    color: white !important;
    text-shadow: 1px 1px 0 rgb(58 58 58 / 67%);
}
.unauthorized {
  font-family: "Roboto";
  text-transform: uppercase;
  color: $main-yellow;
  text-shadow: 1px 1px 0 rgb(58 58 58 / 67%);
}
.logofooter {
  font-family: "Roboto";
  color: white !important;
  text-shadow: 1px 1px 0 rgb(58 58 58 / 67%);
}

.dymium-button-link {
    background: linear-gradient(rgba(0, 0, 0, 0.658), rgba(0, 0, 0, 0.507) 30%, rgba(0, 0, 0, 0.664));
    padding: 8px;
    font-weight: bold;
    backdrop-filter: blur(8px);
    color: white;
    border: 1px rgba(255, 255, 255, 0.747) solid;
    border-radius: 4px;
}
.dymium-button-link:hover {
  color: $main-yellow;
  }
.svgshadow {
  filter: drop-shadow( 2px 2px 0px #000000);
}
.zhigh {
  z-index: 100;
  position: absolute;
  top: 0%;
}
.linklink {
  color: white;
  text-decoration: none
}
.linklink:hover {
  color: #dedede;
}

#navb {
  background: linear-gradient(to right, $dark-blue, $light-blue);
  text-align: left;
}
.sidebar {
  background-color: #E36746;
  color: white;
}
.avatar
{
	border: 2px rgb(57, 79, 61);
	border-radius: 50%;
	height: 36px;
	margin-top: 0px;
	width: 36px;
}
#logout {
  color: white
}
#logout:hover {
  text-decoration: underline;
}
.spider {
  background-image: url(bluebackground.svg) ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
 
}
h5 {
  margin-bottom: 1.2em;
  color: $thick-blue;
}
.thickblue {
  color: $thick-blue;
}
a {
  color: $thick-blue;
}
.btn-dymium {
  background-color: $main-blue !important;  
  color: white !important;
}
.btn-dymium:hover {
  background-color: $dark-blue !important;
}
.btn-dymium.active {
  background-color: $thick-blue !important;
}
.btn-select {
  background-color: $main-blue !important;  
  color: white !important;
}
.btn-select:hover {
  background-color: $dark-blue !important;
}
.btn-select.active {
  background-color: $thick-blue !important;
}

.invalid-feedback  {
  font-size: 0.7em;
}
.valid-feedback {
  font-size: 0.7em;
}
.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: $validation-red;
}
.form-select.is-invalid, .was-validated .form-select:invalid {
  border-color: $validation-red;
}
.invalid-feedback {
    font-size: 0.7em;
    color: $validation-red;
}
.form-control.is-valid, .was-validated .form-control:valid {
  border-color: $validation-green;
}
.form-select.is-invalid, .was-validated .form-select:valid {
  border-color: $validation-green;
}
.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
  color: $validation-green;
}
.valid-feedback {
  font-size: 0.7em;
  color: $validation-green;
}
input:-internal-autofill-selected {
  background-color: white;  
}
.form-label {
  margin-bottom: 0.15rem;
}

.w-12em {
  width: 12em !important;
}
.no-background {
  background-image: none !important;
}
.blue {
  color: $main-blue;  
}
.darkblue {
  color: $dark-blue;  
}
.darkblue:hover {
  color: rgb(228, 248, 255);  
}
.ablue {
  color: $main-blue;  
}
.ablue:hover {
  color: rgb(1, 59, 79); 
}
.nav-tabs .nav-link {
  background-color: #cfd0d2;
  padding: 0.4em 0.8em;
  text-transform: uppercase !important;
  color: $text-gray;
}

#responsive-navbar-nav .nav-link {
  color: white !important; //$dark-blue !important;
  text-shadow: 0.1em 0.1em 0.51em rgba(4, 57, 84, 0.9);
}
.nav-tabs .nav-link.active {
  border-top: solid 6px $main-yellow !important;
  padding-top: 2px;
  border-left: none;
  border-right: none;
  background-color: #eaf2f8;
  border-bottom: none;
  text-shadow: 1px 0px 0px $text-gray;
  color: $text-gray;
}
.nav-tabs {
  background-color: #cfd0d2;
}
  
.datascopeuse {  
  .nav-tabs .nav-link {
    background-color:rgba(206, 215, 222, 0.3);
  }
  .nav-tabs .nav-link.active {
    background-color: #FFFFFF;
    border-bottom-color: #FFFFFF;
  }
  .nav-tabs {
    background-color:rgba(206, 215, 222, 0.3);
  }
  .tab-content {
    background-color: white;
    border-bottom: 1px solid rgb(222, 226, 230);
    border-left: 1px solid rgb(222, 226, 230);
    border-right: 1px solid rgb(222, 226, 230);
    min-height: 4em;
    font-family: monospace;
    padding-top: 0.6em;
  }
}

#search-bar-0 {
  font-size: 0.8em;
  padding-top: 0.9em;
}

#tablecontainer table {
  table-layout: auto;
  width: 100%;
}
.pagination .page-item.active .page-link {
  background-color: $main-blue;  
}
.spinner
{
	animation: round 1.0s infinite ease;
	color: $main-blue;  	
	font-size: 90px;
	margin: 0px auto;
	ms-transform: translateZ(0);
	opacity: 0.6;
	overflow: hidden;
	position: relative;
	text-indent: -9999em;
	transform: translateZ(0);
	webkit-animation: round 1.0s infinite ease;
	webkit-transform: translateZ(0);


}
.hiddenspinner {

  #webkit-animation: fadeIn 0.5s ease;
  transform: none !important;
	#animation: fadeIn 0.7s ease;
  opacity: 0.0;
}
@keyframes fadeIn {
  0% { opacity: 0.6; }
  100% { opacity: 0; }
}
@keyframes round
{
	0%
	{
		transform: rotate(0deg);
		webkit-transform: rotate(0deg);
	}
	100%
	{
		transform: rotate(360deg);
		webkit-transform: rotate(360deg);
	}
}

.form-check-label:hover
{
	cursor: pointer;
	text-decoration: underline !important;
}

.cursor-pointer:hover
{
	cursor: pointer;
}
.trash {
  color: $main-blue;
  &:hover {
    cursor: pointer;
    color: $dark-blue;
  }
}
::placeholder {
  color: $light-gray !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: $light-gray !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: $light-gray !important;
}
.close {
  opacity: 1.0;
  color: $main-blue;
  &:hover {
    color: $dark-blue;
  }
}
.viewport {
  background-color: white;
  color:  $dark-blue;
  padding: 8px;
  margin-bottom: 1.5em;
  border-radius: 4px;
}

.page-link {
  padding-bottom: 0.3em;
  padding-top: 0.3em;
  font-size: .875rem;
}
#scaledtable {
  table-layout: auto !important;
}
#scaledtable-pageDropDown {
  padding-bottom: 0.3em;
  padding-top: 0.3em;
  font-size: .875rem;
}
#schematable {
  table-layout: fixed !important;

}
.highlightOption {
  background: $main-blue !important;
}
.highlight {
  background: $main-blue !important;
}

.multiSelectContainer li:hover {
  background: $main-blue !important;
}
.terminal {
  width: 50%;
  padding: 2px 12px ;
  background: black;
  color: #7AFB4C;
  box-shadow: 0.3em 0.3em 1em rgba(0,0,0,0.5);
  width: fit-content;
  block-size: fit-content;
  font-family: monospace;
  margin-bottom: 0.5em;
}
pre {
  color: #7AFB4C;
}
.clipbtn {
  background-color: $main-blue;
  padding-left: 8px; 
  padding-right: 8px;
  text-align: center;
  padding-top: 6px;
  border-top: 1px solid #efefef;
  border-left: 1px solid #efefef;
  border-bottom: 2px solid $dark-blue;
  border-right: 2px solid $dark-blue;  
  margin-bottom: 0px;
  color: white;
  height: 28px;
  &:hover {
    background-color: $thick-blue;
  

  }
  &:active {
    border-top: 2px solid #efefef;
    border-left: 2px solid #efefef;
    border-bottom: 1px solid $dark-blue;
    border-right: 1px solid $dark-blue;      
    padding-left: 9px; 
    padding-right: 7px;    
    padding-top: 5px;
  }
}

.sidebar_container {
  overflow-x: clip;
  transition: width 0.1s ease-out;
  width: 180px;
}

@media (max-width: $breakdown-width) {
  .sidebar_container {
    width: 38px;
    transition: width 0.1s ease-out;
 

  }  
  .sidebar_container:hover {
    overflow-x:visible;
  }
}  

#testtable td,th {
  width:auto;
  min-width: 60px;
  max-width: 300px;
}
#testtable table {
  table-layout: auto !important;
}
#testtable {
  width: 100% !important;

  overflow: scroll;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  background: $main-blue;
}

.btn-test {
  background-color: #B8DAEE !important;  
  color: white !important; 
  box-shadow: none !important;
  border-color: $dark-blue !important;
}
.btn-test:hover {
  background-color: #8fccef  !important;
}


.btn-test.active {
  background-color: $main-blue !important;
}
.btn-test.active:focus {
  background-color: $main-blue  !important;
  
}
.btn-test.active:hover {
  background-color: rgb(180, 217, 238)   !important;
}

.card {
  color: $dark-blue;
  background-color: rgba(255, 255, 255, 0.5);
  margin-left: 10px;
  margin-right: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0.3em 0.3em 1em rgba(0, 151,206, 0.4);

}

.licard {
  font-size: 1.2em;
  border: 1px solid;
  list-style: none;
  padding: 6px;
  padding-left: 20px !important;
  margin-top: 8px;
  margin-left: 0px !important;
  background-color: #feffff;
  opacity: 0.7;
}
.liouter {
  padding-inline-start: 0px !important;
  margin-block-start: 0em !important;
}

.plusminus {
  cursor: pointer;
  background-color:#ffffff;
}

.plusminus:hover {
  cursor: pointer;
  background-color:#c9e5ef;
}
.transparent
{
	opacity: 0;
}

.palegray {
  background-color: #EBF2F7;
}
.palergray{
  background-color: white;
}

.view {
  box-shadow: rgba(126, 131, 134, 0.56) 0px 22px 70px 4px;
}

.yellowtoggle.react-toggle--checked .react-toggle-track {
  background-color: $dark-yellow
}
.yellowtoggle.react-toggle--checked:hover .react-toggle-track {
  background-color: $main-yellow
}
.yellowtoggle.react-toggle--checked .react-toggle-thumb {
  border-color: $dark-yellow;
  box-shadow: 0px 0px 2px 3px $main-yellow;
}
.yellowtoggle.react-toggle--focus .react-toggle-thumb {
  border-color: $main-yellow;
  box-shadow: 0px 0px 2px 3px $main-yellow;
}
.yellowtoggle.react-toggle:active .react-toggle-thumb {
  border-color: $dark-yellow;
  box-shadow: 0px 0px 5px 5px $dark-yellow;
}

.embed {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  min-height:1600px;
}

.helptab {
  min-height: 1600px;
}
.darkred {
  color: $validation-red;
}
.btn-close {
  font-size: 0.8em;
}
.overflow-x-scroll {
  overflow-x: auto;
  white-space: nowrap;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 3px;
    width: 3px;
  }
  scrollbar-width: thin;
}

.rbt-menu {
  max-height: 600px !important;
}
.w-40em {
  width: 40em !important;
}
.w-20em {
  width: 20em !important;
}
.modal-title {
  overflow-x: scroll;
  margin-right: 4px;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}


/* Static styles for your component */
.progressContainer {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 16px;
  font-size: 19px;

}

.stepBar {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  position: relative;
  margin-bottom: 100px;
}

.stepBarBaseLine {
  background: $light-gray;
  height: 4px;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
}

.stepCircle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepCircleComplete {
  border: 3px solid $thick-blue;
  background-color: $main-blue;
  cursor: pointer;
}

.stepCircleIncomplete {
  border: 3px solid $light-gray;
}
.stepCircleCurrent {
  border: 3px solid $thick-blue;
  background-color: $light-blue;
  color: white !important;
}

.stepLabel {
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
  color: $thick-blue;
  white-space: nowrap;
}

.stepCheckMark {
  font-size: 26px;
  font-weight: 600;
  color: $thick-blue;
  transform: scaleX(-1) rotate(-46deg);
}

.stepNumber {
  font-size: 19px;
  color: $light-gray ;
}

.stepConnector {
  background: $thick-blue;
  height: 4px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  transition: 0.4s ease;
}

.btn-link {
  color: #00527c;
  text-decoration: none;
  &:hover {
    color: $dark-blue;
    text-decoration: none;
  }
  &:focus {
    color: $dark-blue;
    text-decoration: none;
    border: none;
  }
}
.w40em {
  width: 40em !important;
  overflow-x: hidden;
}
.whiteonblue {
  color: white;
  background-color: $main-blue;
}
.underscore:hover
{
	cursor: pointer;
  text-decoration: underline;
}
.rbt-close {
  margin-left: 0.9rem !important;

}
#securityselect  {
  clip-path: inset(-5px 0 -5px -5px);; /* Top Right Bottom Left */
}
#fillbutton {
  clip-path: inset(-5px -5px -5px 0  );; /* Top Right Bottom Left */

}

.modal {
  overflow-y: hidden !important
}