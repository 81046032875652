/* Fixed sidenav, full height */

$primary-color: rgb(255, 158, 24);
$primary-color-text: rgb(31, 31, 32);
$primary-color-2: rgb(222, 139, 22);
$primary-color-2-text: #eee;
$primary-color-3: rgb(220, 158, 140); 
$primary-color-3-text: black;
$primary-pale: rgb(183, 114, 17);
$primary-pale-text: #eeeeee;
$secondary-color: rgb(6, 33, 76); 

.sidenav {
    height: 100% !important;
    
    width: 180px;
    position: sticky;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: $primary-color;
    color: $primary-color-text ;
    overflow-x: hidden;
    padding-top: 13px;
  }
  .navselected {
    background-color: $primary-color-2 !important;
    color: $primary-color-2-text !important;
  }
  .sidesection {
    padding: 4px 4px 14px 30px;
    margin-left : 3px;
    text-align: left;
    color: rgba(255,255,255,1);
  }
  /* Style the sidenav links and the dropdown button */
  .sidenav a, .side-dropdown-btn {
    padding: 5px 7px 5px 8px;
    text-decoration: none;
    font-size: 17px;
  
    display: block;
    border: none;
    background: none;
    width:100%;
    text-align: left;
    cursor: pointer;
    outline: none;
    border-bottom: 0.5px solid $primary-color-3;
   
  }
.side-dropdown-btn {
  padding: 6px 2px 6px 8px
}  
.fa-caret-down {
  margin-top: 0px !important;
}
.green  {
  color: rgb(155, 237, 176);
}
.sidenav .notalink {
  padding: 8px 13px 8px 25px;
  text-decoration: none;
  font-size: 17px;
  color: rgb(235, 235, 235);
  display: block;
  border: none;
  background: none;
  width:100%;
  text-align: left;
  cursor: not-allowed;
  outline: none;
}

/* On mouse-over */
.sidenav  a:hover, .side-dropdown-btn:hover {
  color: $primary-pale-text !important;
  background-color: $primary-pale !important;
}



/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container, .side-dropdown-btn:focus {
  display: none;
  background-color: $primary-color-2;
  color: $primary-color-2-text !important;
  outline: none;    
  border: none;    
}
.selectpad {
  margin-top: '2px' !important;
  padding-left: 0px !important;  
  padding-right: 28px !important;  

}
.selectsign {
  background-color: $primary-pale !important;
  
  padding-left: 0px !important;  
  padding-right: 8px !important;  
}
.selectsigntransparent {
    
  padding-left: 0px !important;  
  padding-right: 8px !important;  
}
.selectionoffset {
padding-left: 0px !important;
padding-top: 0px !important;
padding-bottom: 0px !important;
margin-top: 0px !important;
margin-bottom: 0px !important;
}
/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;    
}
.sidesection strong{
  color: white !important;
}
.sidebartext {
  font-size: 13px !important;
  margin-left: 0.3em;
  color: rgb(243, 227, 205);
}
